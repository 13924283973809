<template>
    <el-container>
        <el-header height="135px">
            <div class="user-info">
                <el-avatar
                    :src="$store.state.userInfo.avatarUrl"
                    :size="70"/>
                <div class="user-info">
                    <div class="user-name">
                        {{ $store.state.userInfo.nickName }}
                    </div>
                    <div class="user-tips">
                        已坚持学习 {{ getDays($store.state.userInfo.createTime) }} 天
                    </div>
                </div>
            </div>
        </el-header>
        <el-container>
            <el-aside width="200px">
                <div class="nav">
                    <el-menu :default-active="activeIndex"
                             router mode="vertical"
                             active-text-color="#f29100"
                             text-color="#3a3a3a"
                             class="title">
                        <el-menu-item index="/my/course">我的课程</el-menu-item>
                        <el-menu-item index="/my/order">我的订单</el-menu-item>
                        <el-menu-item index="/my/record">听课记录</el-menu-item>
                    </el-menu>
                </div>
            </el-aside>
            <el-main style="padding: 0 20px;min-height: calc(100vh - 541px);">
                <div class="content">
                    <router-view/>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            activeIndex: this.$route.path
        }
    },
    created() {
        if (!this.$store.state.token) {
            this.$router.push('/login')
        }
    },
    methods: {
        // 获取距离今天的间隔天数
        getDays(date) {
            if (!date) {
                return 0
            }
            const date1 = new Date(date.replace(/-/g, '/'));
            const date2 = new Date();
            return parseInt((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)); /*不用考虑闰年否*/
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-menu {
    border-right: none !important;
}

::v-deep .el-menu-item {
    background-color: transparent !important;
    border-left: 4px solid #FFFFFF;
}

::v-deep .el-menu-item.is-active {
    border-left: 4px solid $sr-main-color;
    background: rgb(249, 249, 249) !important;
}

.user-info {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 15px 30px;
    margin-left: -20px;
    display: flex;
    align-items: center;

    .user-info {
        margin-left: 15px;
        display: block;

        .user-name {
            line-height: 30px;
            font-weight: 700;
            font-size: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .user-tips {
            font-size: 15px;
            font-weight: 500;
            color: #8798ad;
            margin-top: 6px;
        }
    }

}

.nav {
    background-color: #FFFFFF;
    padding: 20px 0;
    min-height: 300px;
    border-radius: 4px;

    .title {
        li {
            font-size: 18px;
            text-align: center;

            &li:hover {
                background-color: transparent !important;
            }
        }
    }
}

.content {
    background-color: #FFFFFF;
    padding: 30px;
    min-height: 700px;
}

</style>